import React from "react";
import Gigs from "../gigs/Gigs";
import Gig from "../gig/Gig";

import "./FutureGigs.scss";

function FutureGigs() {
  return (
    <div>
      <Gigs title="Kommende Auftritte" list>
        {/* <Gig
          date="So., 15.10.23"
          time="ab ca. 13:00/14:00 Uhr"
          locality="HamburG. Süd TrafikCafé - Schreibwaren"
          city="Kemnat (Ostfildern)"
        />
        <Gig
          date="Sa., 11.11.23"
          time
          locality="Central Café"
          city="Fürstenfeldbruck"
        /> */}
        {/* <Gig
          date="Sa., 09.12.23"
          time="21:00 Uhr"
          locality="100bpm"
          city="Kleinbottwar"
        /> */}
        {/* <Gig date="So., 21.01.24" locality="Privatveranstaltung - Geburtstag" />
        <Gig
          date="Fr., 02.02.24"
          time
          locality="Holzwarth's"
          city="Marbach am Neckar"
        /> */}
        {/* <Gig
          date="Sa., 24.02.24"
          locality="Bubbles Café"
          city="Ludwigsburg"
          time="15 bis 17 Uhr"
        />
        <Gig
          date="Fr., 01.03.24"
          time="21:00 Uhr"
          locality="100bpm"
          city="Kleinbottwar"
        />
        <Gig date="Fr., 15.03.24" time locality="TIK" city="Stuttgart" />
        <Gig date="Sa., 23.03.24" time locality="Jan's Keller" city="Stuttgart" /> */}
        {/* <Gig
          date="Fr., 26.04.24"
          time="21:30"
          locality="Holzwarth's"
          city="Marbach am Neckar"
        />
        <Gig
          date="Sa., 27.04.24"
          time
          locality="Open Stage Vitruvia"
          city="Stuttgart"
        /> */}
        {/* <Gig
          date="Sa., 18.05.24"
          time="19:30"
          locality="Freibad"
          city="Mundelsheim"
        />
        <Gig
          date="Fr., 07.06.24"
          time
          locality="Central Café"
          city="Fürstenfeldbruck"
        />
        <Gig date="Sa., 08.06.24" locality="Privatveranstaltung" /> */}
        {/* <Gig date="Do., 13.06.24" locality="Conne Island" city="Leipzig" />
        <Gig date="Mi., 03.07.24" locality="Benzin" city="Stuttgart" /> */}
        {/* <Gig
          date="Sa., 06.07.24"
          time="18:00"
          locality="KBV"
          city="Stuttgart"
        /> */}
        {/* <Gig date="Fr., 26.07.24" locality="Altes Fuhrmannshaus" city="Plochingen" />
        <Gig date="Sa., 27.07.24" locality="Privatveranstaltung - Hochzeit" /> */}
        {/* <Gig date="Sa., 03.08.24" locality="Rockabilly Days" city="Asperg" /> */}
        <Gig date="Mi., 11.09.24" locality="Benzin" city="Stuttgart" />
        <Gig date="Sa., 21.09.24" locality="Wohnzimmerkonzert" city="Karlsruhe" />
        {/* <Gig
          locality="Weitere Auftritte sind aktuell in Planung"
          city=""
        /> */}
      </Gigs>
      <p className="time-disclaimer">
        Uhrzeiten ändern sich in seltenen Fällen spontan. Aktuelle Infos immer
        auf{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/SamwhiskeyMusic/events/?ref=page_internal"
        >
          Facebook
        </a>{" "}
        in der entsprechenden Veranstaltung.
      </p>
    </div>
  );
}

export default FutureGigs;
